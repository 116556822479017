$transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);


body {
  margin: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  // background-color: #2a60cc;
  height: 100vh;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.center {
  display: flex;
  justify-content: center;
}
.total-wrapper {
  width: 800px;
  height: 92vh;
  max-height: 92vh;
  background-color: #F4F5F7;
}

@media only screen and (max-width: 600px) {
  .total-wrapper {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
.swiper-pagination {
  bottom: 93% !important;
  padding-bottom: 10px;
}

.swiper-wrapper {
  padding-inline-start: 0;
}
.swiper {
  /* width: 100%;
  height: 100%; */
}
.swiper-slide img {
  display: block;
  
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  /* max-height: 45vh; */
  object-fit: cover;
  margin-top: 25px;
}
@media only screen and (max-width: 600px) {
  .swiper-slide img {
    width: 100%;
  }
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 30px;
}
.section-slide-wrapper {
  margin: 40px;
  margin-top: 50px;
}
.article-overview-slide-wrapper {
  margin: 40px;
  margin-top: 10vh;
  display: flex;
  align-items: center;
}
.lottie-scroll {
  width: 80px;
  height: 80px;
}
.lottie-drag {
  height: 80px;
  width: 300px;
}
.module-wrapper {
  margin: 40px;
  margin-top: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1,125rem;
  line-height: 1,75rem;
}


details {
  cursor: pointer;
}
details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}

details > summary {
  list-style-type: '';
}

details > summary::before {
  content: '▶️ ';
  /* content:  url(https://uxwing.com/wp-content/themes/uxwing/download/02-arrow-direction/arrows-down.png); */
}

/* */
details[open] > summary::before {
  content: '🔽 ';
}

details {
  max-width: 600px;
  border-radius: 12px;
  margin: 20px;
  padding: 17px;
  background-color: #e2edf1;
}

@keyframes sweep {
  0%    {opacity: 0; transform: translateX(-10px)}
  100%  {opacity: 1; transform: translateX(0)}
}


.collapse {
  transition: height $transition;
}

.content {
  // padding: 2rem 0;
  transition: transform $transition;
  will-change: transform;
  $offset: 10px;

  &.collapsing {
    transform: translateY(-$offset);
  }
  &.collapsed {
    transform: translateY(-$offset);
  }
  &.expanding {
    transform: translateX(0px);
  }
  &.expanded {
    transform: translateX(0px);
  }
}


.guide-image {
  width: 100vw;
  height: 61vh !important;
}


.btn {
  width: 100%;
  /* box-shadow: 0 2px 6px #ccc; */
  background-color: #ffffff;
  border: none;
  transition: background-color $transition;
  font-family: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  padding-bottom: 15px;

}
.truncate {
  display: inline-block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item--active {
  .btn {
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
  }
}
.collapse {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.station {
  height: 100%;
  width: 100%;
  position:relative;
}
.app {
  position:absolute;
  width: 100%;
  height: 180px;
  bottom:0;
  margin-bottom: 50px;
}
.modules-wrapper {
  position:relative;
  background-color: #F4F5F7;
  border-radius: 15px;
}
.item {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 7px;
  padding-top: 7px;
}

.module-element {
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
}

.rhap_progress-indicator {
  background-color: #FF4342;
}
.rhap_play-pause-button {
  color: #000000;
}
.rhap_progress-section {
  flex: 20 1 auto;
}
.rhap_controls-section {
  flex: none;
  margin-top: -3px;
}
.rhap_container {
  padding-left: 0px;
}

.rhap_container {
  box-shadow: none;
}
.rhap_time {
  color: #225E78;
}

.rhap_progress-bar {
  height: 4px;
}
.module-icon {
  height: 35px;

}
.icon {
  display: inline-flex;
  align-self: center;
}

.icon svg, .icon img {
  height: 2em;
  width: 2em;
  fill: currentColor;
}

.icon.baseline svg, .icon img {
  top: .6em;
  position: relative;
}
.control-icon{
  top: -0.4em;
  transform: scale(2);
  position: relative;
  fill: currentColor;
  display: inline-flex;
  align-self: center;
}